import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { MoonIcon, SunIcon, SunMoonIcon } from 'lucide-react'
import { useEffect } from 'react'
import { SegmentedControl } from '~/components/segmented-control'
import { SegmentedControlRootProps } from '~/components/segmented-control/segmented-control'
import {
  FontFamily,
  Theme,
  ThemeVariant,
  useFontFamily,
  useTheme,
  useThemeVariant,
} from '~/feat/theme/state'
import { useAlternativeUI } from '~/lib/flags'
import { cn } from '~/lib/utils'

const variantMap = {
  contrast: 'theme-contrast-variant',
  gray: 'theme-gray-variant',
  mauve: 'theme-mauve-variant',
  sand: 'theme-sand-variant',
  slate: 'theme-slate-variant',
} satisfies Record<ThemeVariant, string>

const variantClasses = Object.values(variantMap)

function setVariant(variant: ThemeVariant | null) {
  const html = document.body

  if (!variant) {
    html.classList.remove(...variantClasses)
    return
  }

  const variantClass = variantMap[variant]
  html.classList.add(variantMap[variant])
  html.classList.remove(...variantClasses.filter((v) => v !== variantClass))
}

function setLightTheme() {
  const html = document.body
  html.classList.add('light')
  html.classList.remove('dark')
}

function setDarkTheme() {
  const html = document.body
  html.classList.add('dark')
  html.classList.remove('light')
}

export function ThemeSwitcher() {
  const [theme] = useTheme()
  const [variant] = useThemeVariant()
  const alt = useAlternativeUI()

  const themeValue = theme

  useEffect(() => {
    if (themeValue === Theme.dark) {
      setDarkTheme()
      return
    }

    if (themeValue === Theme.light) {
      setLightTheme()
      return
    }

    if (themeValue === Theme.system) {
      const dark = window.matchMedia('(prefers-color-scheme: dark)')

      if (dark.matches) {
        setDarkTheme()
      } else {
        setLightTheme()
      }

      const onMatchesChange = (e: MediaQueryListEvent) => {
        const { matches: matchesDark } = e
        if (matchesDark) {
          setDarkTheme()
        } else {
          setLightTheme()
        }
      }

      dark.addEventListener('change', onMatchesChange)

      return function cleanup() {
        dark.removeEventListener('change', onMatchesChange)
      }
    }
  }, [themeValue, alt])

  useEffect(() => {
    if (!alt) {
      setVariant(null)
    } else {
      setVariant(variant)
    }
  }, [variant, alt])

  useEffect(() => {
    const html = document.body
    if (alt) {
      html.classList.add('alt-ui')
    } else {
      html.classList.remove('alt-ui')
    }
  }, [alt])

  return null
}

export function ThemeSegmentedControl() {
  const [theme, setTheme] = useTheme()

  function handleThemeChange(nextValue: string) {
    if (nextValue) {
      setTheme(nextValue as Theme)
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-1">
      <SegmentedControl.Root
        value={theme}
        onValueChange={handleThemeChange}
        className="w-full"
        aria-label="Theme selection"
      >
        <SegmentedControl.Item
          aria-label="Switch to system mode"
          value={Theme.system}
          className="flex flex-row items-center justify-center gap-2"
        >
          <SunMoonIcon size="16" /> System
        </SegmentedControl.Item>
        <SegmentedControl.Item
          aria-label="Switch to dark mode"
          value={Theme.dark}
          className="flex flex-row items-center justify-center gap-2"
        >
          <MoonIcon size="16" /> Dark
        </SegmentedControl.Item>
        <SegmentedControl.Item
          aria-label="Switch to light mode"
          value={Theme.light}
          className="flex flex-row items-center justify-center gap-2"
        >
          <SunIcon size="16" /> Light
        </SegmentedControl.Item>
      </SegmentedControl.Root>
    </div>
  )
}

export function FontSwitcher() {
  const [font] = useFontFamily()

  useEffect(() => {
    const html = document.body
    if (font === FontFamily.dual) {
      html.classList.add('font-dual')
      html.classList.remove('font-sans', 'font-inter')
    } else if (font === FontFamily.inter) {
      html.classList.add('font-inter')
      html.classList.remove('font-sans', 'font-dual')
    } else {
      html.classList.add('font-sans')
      html.classList.remove('font-dual', 'font-inter')
    }
  }, [font])

  return null
}

export function ThemeVariantSegmentedControl(props: SegmentedControlRootProps) {
  const [themeVariant, setThemeVariant] = useThemeVariant()

  function handleThemeVariantChange(nextValue: string) {
    if (nextValue) {
      console.log('next variant', nextValue)
      setThemeVariant(nextValue as ThemeVariant)
    }
  }

  return (
    <div className="flex flex-1 flex-col gap-1">
      <SegmentedControl.Root
        aria-label="Theme variant selection"
        value={themeVariant}
        onValueChange={handleThemeVariantChange}
        className="w-full"
        {...props}
      >
        <SegmentedControl.Item
          value={ThemeVariant.gray}
          aria-label="Switch to pure gray variant"
        >
          Gray
        </SegmentedControl.Item>
        <SegmentedControl.Item
          value={ThemeVariant.slate}
          aria-label="Switch to slate variant"
        >
          Slate
        </SegmentedControl.Item>
        <SegmentedControl.Item
          value={ThemeVariant.mauve}
          aria-label="Switch to mauve variant"
        >
          Mauve
        </SegmentedControl.Item>
        <SegmentedControl.Item
          value={ThemeVariant.sand}
          aria-label="Switch to sand variant"
        >
          Sand
        </SegmentedControl.Item>
      </SegmentedControl.Root>
    </div>
  )
}

const itemContainerClasses = cn(
  'group flex flex-1 flex-col items-center gap-1',
  'text-app-text data-[state=on]:text-app-text-hi',
  'rounded-lg outline-offset-4',
)

const itemShowcaseClasses = cn(`
  flex h-16 w-full flex-col items-center justify-center rounded-xl
  border border-app-divider-lo bg-app-overlay/2 px-4
  text-[32px] font-normal leading-none text-app-text-lo 
  transition-colors
  group-data-[state=on]:border-app-border group-data-[state=on]:bg-app-surface
  group-data-[state=on]:shadow group-enabled:group-data-[state=on]:text-app-text-hi 
  group-enabled:group-data-[state=off]:group-hover:border-app-divider
  group-enabled:group-data-[state=off]:group-hover:bg-app-overlay/4 
  dark:group-data-[state=on]:bg-app-overlay/8
`)

const itemNameClasses = cn('text-body-2 font-semibold')

export function FontFamilyControl() {
  const [font, setFont] = useFontFamily()

  return (
    <ToggleGroupPrimitive.Root
      aria-label="Font family selection"
      type="single"
      className="flex w-full flex-row gap-5"
      value={font}
      onValueChange={(font) => {
        if (font) {
          setFont(font as FontFamily)
        }
      }}
    >
      <ToggleGroupPrimitive.Item
        aria-label="Switch to Metropolis font family"
        value={FontFamily.metropolis}
        className={cn(itemContainerClasses, 'font-sans')}
      >
        <div className={cn(itemShowcaseClasses, 'font-sans')}>Abc</div>
        <span className={(cn(itemNameClasses), 'font-sans')}>Metropolis</span>
      </ToggleGroupPrimitive.Item>
      <ToggleGroupPrimitive.Item
        aria-label="Switch to balanced variant with Metropolis for headings and Inter for body text"
        value={FontFamily.dual}
        className={cn(itemContainerClasses, 'font-inter')}
      >
        <div className={cn(itemShowcaseClasses)}>
          <div className="flex flex-col gap-[2px] text-left">
            <span className="font-sans text-body-2 font-bold leading-none">
              Title
            </span>
            <span className="font-inter text-body-3 font-normal leading-none">
              Body text
            </span>
          </div>
        </div>
        <span className={cn(itemNameClasses, 'font-inter')}>Recommended</span>
      </ToggleGroupPrimitive.Item>
      <ToggleGroupPrimitive.Item
        aria-label="Switch to Inter font family"
        value={FontFamily.inter}
        className={cn(itemContainerClasses, 'font-inter')}
      >
        <div className={cn(itemShowcaseClasses, 'font-inter')}>Abc</div>
        <span className={cn(itemNameClasses, 'font-inter')}>Inter</span>
      </ToggleGroupPrimitive.Item>
    </ToggleGroupPrimitive.Root>
  )
}
