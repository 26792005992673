import { redirect } from 'react-router-dom'
import { auth } from '~/lib/auth'
import { q } from '~/lib/query'
import { getOrganizationParams } from '~/routes/(auth)/(org)/utils'

export const loader = q.loader((_) => async ({ request, params }) => {
  const user = auth.getUserPayload()
  const { organizationSlug } = getOrganizationParams(params)
  if (user?.organizationSlug !== organizationSlug) {
    const url = new URL(request.url)
    const search = `?next=${url.pathname}${url.search}`
    return redirect(`/${organizationSlug}/switch${search}`)
  }
  return null
})
