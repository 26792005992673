import { HTTPError } from 'ky'
import { isUnpackedError } from '~/lib/http'
import { q } from '~/lib/query'

interface CustomError {
  status?: number
  errorType?: string
  message: string
}

interface Config {
  errors?: CustomError[]
}

/**
 * Retrieves an error message based on the provided configuration.
 * Prioritizes custom error messages, falling back to default messages.
 * Automatically ranks custom errors based on status and errorType.
 *
 * @example
 * ```typescript
 * const config = {
 *   errors: [
 *     { status: 403, message: 'Access denied' }, // lower priority, will be checked last
 *     { status: 404, errorType: 'NotFound', message: 'Project not found' }, // higher priority
 *   ]
 * };
 *
 * const message = await getErrorMessage(error, config);
 * // 'Resource not found' or 'Access denied' or a default message based on status
 * ```
 */

export async function getErrorMessage(
  error: q.UnpackedHttpError | HTTPError,
  config: Config = {},
): Promise<string> {
  if (config.errors?.length) {
    if (error instanceof HTTPError) {
      error = await q.unpackHttpError(error)
    }
    const errorValue = q.getGenericError(error.data)
    const matchedError = matchError(
      error.status,
      errorValue?.errorType,
      config.errors,
    )
    if (matchedError) {
      return matchedError.message
    }
  }

  if (error instanceof HTTPError) {
    return getDefaultErrorMessage(error.response.status)
  }

  return getDefaultErrorMessage(error.status)
}

function matchError(
  status: number,
  errorType: string | undefined,
  errors: CustomError[],
): CustomError | undefined {
  // Sort errors from most specific to least specific
  const sortedErrors = [...errors].sort(
    (a, b) => getErrorScore(b) - getErrorScore(a),
  )

  // Find the first matching error
  return sortedErrors.find(
    (error) =>
      (error.status === status || error.status === undefined) &&
      (error.errorType === errorType || error.errorType === undefined),
  )
}

function getErrorScore(error: CustomError): number {
  return (
    (error.status !== undefined ? 1 : 0) +
    (error.errorType !== undefined ? 2 : 0)
  )
}

function getDefaultErrorMessage(status: number): string {
  const defaultMessages: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    409: 'Duplication error',
    500: 'Internal Server Error',
  }
  return defaultMessages[status] ?? 'An unexpected error occurred'
}

export function shouldThrowLayoutError(error: unknown): boolean {
  if (isUnpackedError(error)) {
    const errorValue = q.getGenericError(error.unpacked.data)
    if (
      [
        q.serverErrorType.orgNotFound,
        q.serverErrorType.projectNotFound,
        q.serverErrorType.almInstanceNotFound,
      ].includes(errorValue.errorType)
    ) {
      return true
    }
  }
  return false
}
//TODO: re-use shouldThrowLayoutError logic?
export function getLayoutErrorProps(
  error: unknown,
): { title: string; description: string } | null {
  if (q.isServerError(error)) {
    const errorValue = q.getGenericError(error)

    switch (errorValue.errorType) {
      case q.serverErrorType.orgNotFound:
        return {
          title: 'Organization not found',
          description: '',
        }

      case q.serverErrorType.projectNotFound:
        return {
          title: 'Project not found',
          description: '',
        }

      case q.serverErrorType.almInstanceNotFound:
        return {
          title: 'Branch not found',
          description: '',
        }

      default:
        return null
    }
  }

  return null
}
