import {
  ChangeEmailBody,
  RenameUserBody,
  SetAvatar,
  SsoAuthTokenDto,
  SsoUserInfo,
} from '@userway/cicd-api'
import { http, httpApiSso } from '~/lib/http'
import { q } from '~/lib/query'

export async function saveAccountFirstLastName(
  payload: RenameUserBody,
  signal?: AbortSignal | undefined,
) {
  const res = await http
    .post(`rename`, { json: payload, signal: signal || null })
    .json()
  return res
}

export async function removeAccountAvatar(signal?: AbortSignal | undefined) {
  const res = await http
    .delete(`avatar`, {
      signal: signal || null,
    })
    .json()
  return res
}

export async function getSsoUserInfo() {
  const res = await httpApiSso.get('user/service-user/info').json()
  return res as q.ResponseBody<SsoUserInfo>
}

export async function getUserInfo() {
  const res = await http.get('user/info').json()
  return res as q.ResponseBody<SsoUserInfo>
}

export interface UpdateUserPasswordBody {
  oldPassword: string
  newPassword: string
}

export async function changeUserPassword(payload: UpdateUserPasswordBody) {
  const res = await httpApiSso
    .post(`user/service-user/change-password`, { json: payload })
    .json()
  return res
}
export async function changeUserEmail(payload: ChangeEmailBody) {
  const res = await http.post(`email/change`, { json: payload }).json()
  return res as q.ResponseBody<SsoAuthTokenDto>
}

export async function changeUserAvatar(payload: SetAvatar) {
  const res = await http.put(`avatar`, { json: payload }).json()
  return res
}

export enum TeamScope {
  WIDGET_MANAGE_TEAM = 'WIDGET_MANAGE_TEAM',
  SCANNER_TEAM = 'SCANNER_TEAM',
  CA11Y_TEAM = 'CA11Y_TEAM',
}

export interface ChangeDefaultTeamBody {
  newDefaultAccountId: string
  teamScope: TeamScope
}

export async function changeDefaultTeam(payload: ChangeDefaultTeamBody) {
  const res = await httpApiSso
    .patch(`user/service-user/default-team`, { json: payload })
    .json()
  return res
}
