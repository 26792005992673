import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { motion } from 'framer-motion'
import { createContext, useContext, useId } from 'react'
import { cn, tw } from '~/lib/utils'

type SegmentedControlContextValue = {
  id: string
  value?: string | undefined
}

const SegmentedControlContext = createContext<SegmentedControlContextValue>({
  id: '',
  value: '',
})

const root = tw(`
  relative flex h-10 select-none flex-row gap-1 overflow-hidden 
  rounded-lg border border-app-divider bg-app-overlay/4 p-1
`)

export type SegmentedControlRootProps = Omit<
  ToggleGroupPrimitive.ToggleGroupSingleProps,
  'type'
>

export function SegmentedControlRoot(props: SegmentedControlRootProps) {
  const id = useId()
  const { children, className, ...rootProps } = props

  return (
    <SegmentedControlContext.Provider value={{ id, value: rootProps.value }}>
      <ToggleGroupPrimitive.Root
        {...rootProps}
        className={cn(root, className)}
        type="single"
      >
        {children}
      </ToggleGroupPrimitive.Root>
    </SegmentedControlContext.Provider>
  )
}

const item = tw(`
  relative isolate flex-1 rounded p-1 text-body-2 font-medium 
  text-app-text-lo transition-colors data-[state=on]:font-semibold 
  data-[state=on]:text-app-text-hi enabled:data-[state=off]:hover:text-app-text
`)

const activeHighlight = tw(`
  absolute inset-0 -z-10 rounded bg-app-surface
  shadow dark:bg-app-overlay/8
`)

export type SegmentedControlItemProps =
  ToggleGroupPrimitive.ToggleGroupItemProps

export function SegmentedControlItem(props: SegmentedControlItemProps) {
  const { id: rootId, value: rootValue } = useContext(SegmentedControlContext)
  const { children, className, ...itemProps } = props

  const active = rootValue === itemProps.value

  return (
    <ToggleGroupPrimitive.Item {...itemProps} className={cn(item, className)}>
      {active && (
        <motion.div
          className={activeHighlight}
          layoutId={`segmented-control-active-${rootId}`}
          transition={{ type: 'spring', bounce: 0.2, duration: 0.3 }}
        />
      )}
      {children}
    </ToggleGroupPrimitive.Item>
  )
}

export const SegmentedControl = {
  Root: SegmentedControlRoot,
  Item: SegmentedControlItem,
}
