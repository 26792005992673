import { $coerce } from '~/lib/utils/coerce'

type FormDataInit = FormData | HTMLFormElement | undefined

type Config = {
  /**
   * Filter out empty values, such as empty strings
   * @default false
   */
  noEmpty?: boolean | undefined
}

/**
 * Accepts HTMLFormElement or FormData and turns it into URLSearchParams.
 * @param init
 * @param config
 * @returns
 */
export function formDataToSearchParams(
  init: FormDataInit,
  config: Config = {},
) {
  const formData = init instanceof FormData ? init : new FormData(init)
  if (config.noEmpty) {
    const entries = Array.from(formData).filter(([_key, value]) => value)
    return new URLSearchParams($coerce(entries))
  }
  /** FormData value can be a File, so we need to coerce it to a string record */
  return new URLSearchParams($coerce(formData))
}

type SearchObject = Record<string, number | string | string[] | null>

/**
 * Create a URLSearchParams from an object using API conventions
 * @param object
 * @returns
 */
export function encodeSearchParams(
  object: SearchObject,
  config?: {
    /** Use API conventions. For example: `key[]` instead of `key` for arrays */
    server?: boolean
  },
): URLSearchParams {
  const searchParams = new URLSearchParams()

  Object.keys(object).map((key) => {
    const value = object[key]
    if (value) {
      if (Array.isArray(value)) {
        const searchKey = config?.server ? `${key}[]` : key
        for (const valueEntry of value) {
          searchParams.append(searchKey, valueEntry)
        }
      } else if (typeof value === 'number') {
        searchParams.set(key, String(value))
      } else {
        searchParams.set(key, value)
      }
    }
  })

  return searchParams
}
