import { atom, useAtom, useAtomValue } from 'jotai'
import { z } from 'zod'
import { flagsStorageKey } from '~/feat/storage/const'
import { tryto } from '~/lib/utils/tryto'

const currentVersion = 3

const flagsSchema = z.object({
  version: z.number(),
  root: z
    .object({
      alternativeUI: z.boolean().optional(),
      alternativeSkeleton: z.boolean().optional(),
    })
    .catch(() => ({})),
  login: z
    .object({
      showSSO: z.boolean().optional(),
    })
    .catch(() => ({})),
  qualityGates: z
    .object({
      hideNavLoaders: z.boolean().optional(),
    })
    .catch(() => ({})),
  navTabs: z.object({
    pendingAnimation: z.boolean().optional(),
  }),
})

export type Flags = z.infer<typeof flagsSchema>

const initial: Flags = {
  version: currentVersion,
  login: {},
  root: {
    alternativeUI: true,
    alternativeSkeleton: false,
  },
  qualityGates: {},
  navTabs: {},
}

function hydrate(): Flags {
  const fromStorage = localStorage.getItem(flagsStorageKey)
  if (fromStorage) {
    const [data] = tryto.run(() => JSON.parse(fromStorage))
    if (data && typeof data === 'object') {
      if ('version' in data) {
        if (data.version === currentVersion) {
          try {
            return flagsSchema.parse(data)
          } catch (e) {}
        }
      }
    }
  }
  localStorage.setItem(flagsStorageKey, JSON.stringify(initial))
  return initial
}

export const baseFlagsAtom = atom(hydrate())

export const flagsAtom = atom(
  (get) => get(baseFlagsAtom),
  (_, set, value: Flags) => {
    set(baseFlagsAtom, value)
    if (value) {
      localStorage.setItem(flagsStorageKey, JSON.stringify(value))
    } else {
      localStorage.removeItem(flagsStorageKey)
    }
  },
)

export function useFlags() {
  return useAtomValue(flagsAtom)
}

export function useAlternativeUI() {
  const flags = useFlags()
  return Boolean(flags.root.alternativeUI)
}

export function useSetAlternativeUI() {
  const [flags, setFlags] = useFlagsState()
  return (on: boolean) => {
    setFlags({
      ...flags,
      root: {
        ...flags.root,
        alternativeUI: on,
      },
    })
  }
}

export function useFlagsState() {
  return useAtom(flagsAtom)
}

export function useResetFlags() {
  const [, set] = useFlagsState()
  return () => set(initial)
}
