export type Result<T> = [T, null] | [null, Error]

/**
 * Handle try-catch inline
 * @example
 * function throwableGetUser(userId: string) {...}
 * const [user, error] = tryto(() => throwableGetUser(42))
 * if (error) {
 *   // handle error
 * } else {
 *   // user is available here
 * }
 */
function trytoSync<A>(thunk: () => A): Result<A> {
  try {
    return [thunk(), null]
  } catch (error) {
    if (error instanceof Error) {
      return [null, error]
    }

    /* In React Router responses can be thrown to render error boundary */
    throw error
  }
}

/**
 * Handle async try-catch inline
 * @example
 * async function throwableGetUser(userId: string) {...}
 * const [user, error] = await tryto(throwableGetUser(42))
 * if (error) {
 *   // handle error
 * } else {
 *   // user is available here
 * }
 */
async function trytoPromise<A>(promise: Promise<A>): Promise<Result<A>> {
  try {
    return [await promise, null]
  } catch (error) {
    if (error instanceof Error) {
      return [null, error]
    }

    /* In React Router responses can be thrown to render error boundary */
    throw error
  }
}

export const tryto = { run: trytoSync, promise: trytoPromise }
