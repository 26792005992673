import { Button } from '~/components/button'
import { TextLink } from '~/components/text-link'

export function RootErrorBoundary() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-6">
      <div>
        <p className="text-center text-body-2 text-app-text-hi">
          An unexpected error occurred.
          <br />
          <TextLink to={window.location.href}>Reload the application.</TextLink>
        </p>
      </div>
      <span className="select-none text-body-2 font-medium text-app-text-lo">
        OR
      </span>
      <div className="flex flex-col items-center gap-2">
        <p className="text-body-3 text-app-text">
          Reset the state of application, sign out, and reload
        </p>
        <Button
          variant="bordered"
          tone="primary"
          size="sm"
          onClick={() => {
            localStorage.clear()
            window.location.reload()
          }}
        >
          Reset the application
        </Button>
      </div>
    </div>
  )
}
