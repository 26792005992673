import { queryOptions } from '@tanstack/react-query'
import {
  getProjectSettings,
  searchProjects,
  searchProjectsFacets,
  getProjectInfo,
  getProjectSetup,
  getBitbucketPipelineVersion,
  getProjectDetails,
} from './api'
import * as fromSearch from '~/feat/projects/search'
import { time } from '~/lib/utils/time'

const projects = 'projects'

export const projectQueries = {
  all: (organizationSlug: string) => {
    return [organizationSlug, projects] as const
  },
  search: (organizationSlug: string, filters: fromSearch.Filters) => {
    return queryOptions({
      queryKey: [...projectQueries.all(organizationSlug), 'search', filters],
      queryFn: ({ signal }) => {
        return searchProjects(organizationSlug, filters, signal)
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  facets: (organizationSlug: string, filters: fromSearch.Filters) => {
    return queryOptions({
      queryKey: [...projectQueries.all(organizationSlug), 'facets', filters],
      queryFn: ({ signal }) => {
        return searchProjectsFacets(organizationSlug, filters, signal)
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  settings: (organizationSlug: string, project: string) => {
    return queryOptions({
      queryKey: [...projectQueries.all(organizationSlug), project, 'settings'],
      queryFn: ({ signal }) => {
        return getProjectSettings(organizationSlug, project, signal)
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  info: (organizationSlug: string, projectName: string) => {
    return queryOptions({
      queryKey: [
        ...projectQueries.all(organizationSlug),
        projectName,
        'info',
      ] as const,
      queryFn: () => {
        return getProjectInfo(organizationSlug, projectName)
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  setup: (organizationSlug: string, projectName: string) => {
    return queryOptions({
      queryKey: [
        ...projectQueries.all(organizationSlug),
        projectName,
        'setup',
      ] as const,
      queryFn: () => {
        return getProjectSetup(organizationSlug, projectName)
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  details: (organizationSlug: string, projectName: string) => {
    return queryOptions({
      queryKey: [
        ...projectQueries.all(organizationSlug),
        projectName,
        'details',
      ],
      queryFn: ({ signal }) =>
        getProjectDetails(projectName, organizationSlug, signal),
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
  bitbucketPipelineVersion: () => {
    return queryOptions({
      queryKey: ['bitbucket-pipeline-version'] as const,
      queryFn: () => {
        return getBitbucketPipelineVersion()
      },
      staleTime: time.fromSeconds(0).getMs(),
    })
  },
}
