import { createContext, useContext } from 'react'
import { cn } from '~/lib/utils'

type DivElementProps = React.ComponentPropsWithoutRef<'div'>

type Size = 'narrow' | 'base' | 'wide' | 'full'
type Variant = 'base' | 'sticky-sidebar'
type Variants = { size?: Size; variant?: Variant }

type SharedProps = DivElementProps & Variants

const ContainerContext = createContext<Required<Variants>>({
  size: 'base',
  variant: 'base',
})

function ContainerRoot({
  className,
  size = 'base',
  variant = 'base',
  ...props
}: SharedProps) {
  return (
    <ContainerContext.Provider value={{ size, variant }}>
      <div
        {...props}
        className={cn(
          'm-auto flex h-full flex-row justify-center',
          size === 'full' ? 'w-full' : 'max-w-screen-xl',
          variant === 'sticky-sidebar' && 'm-0 w-full max-w-full justify-start',
          className,
        )}
      />
    </ContainerContext.Provider>
  )
}

const contentSizes: Record<Size, string> = {
  narrow: 'max-w-[940px]',
  base: 'max-w-[1024px]',
  wide: 'max-w-[1200px]',
  full: 'max-w-full',
}

function ContainerContent({
  className,
  size: sizeFromProps,
  variant: variantFromProps,
  ...props
}: SharedProps) {
  const ctx = useContext(ContainerContext)

  const size = sizeFromProps || ctx.size
  const variant = variantFromProps || ctx.variant

  return (
    <div
      {...props}
      className={cn(
        contentSizes[size],
        variant === 'sticky-sidebar' && 'mx-auto justify-center',
        'flex-1 px-6 pb-12 pt-6',
        className,
      )}
    />
  )
}

const asideSizes: Record<Size, string> = {
  narrow: 'w-[200px]',
  base: 'w-[240px]',
  wide: 'w-[280px]',
  full: 'w-[280px]',
}

function ContainerAside({
  className,
  size: sizeFromProps,
  ...props
}: SharedProps) {
  const ctx = useContext(ContainerContext)

  const size = sizeFromProps || ctx.size

  return (
    <div
      {...props}
      className={cn(
        asideSizes[size],
        className,
        // variant === 'sticky-sidebar' && 'bg-neutral-100 bg-opacity-80',
        'hidden h-full px-4 pb-12 pt-6 lg:block',
      )}
    />
  )
}

export const Container = {
  Root: ContainerRoot,
  Aside: ContainerAside,
  Content: ContainerContent,
}
