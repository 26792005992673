import { queryOptions } from '@tanstack/react-query'
import { getSsoUserInfo, getUserInfo } from '~/feat/user/api'

export const userQueries = {
  all: ['user'],
  ssoInfo: () => {
    return queryOptions({
      queryKey: [...userQueries.all, 'sso-info'] as const,
      queryFn: () => {
        return getSsoUserInfo()
      },
    })
  },
  info: () => {
    return queryOptions({
      queryKey: [...userQueries.all, 'info'] as const,
      queryFn: () => {
        return getUserInfo()
      },
    })
  },
}
