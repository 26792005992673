import { invariant } from '@epic-web/invariant'
import { GrantedMemberRole } from '@userway/cicd-api'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithQuery } from 'jotai-tanstack-query'
import { z } from 'zod'
import { refreshStorageKey, tokenStorageKey } from '~/feat/storage/const'
import { userQueries } from '~/feat/user/queries'
import { store } from '~/lib/store'

type TokenValue = string | null | undefined

/**
 * Only return the value from storage if it's a string
 */
function hydrateStringItem(key: string): TokenValue {
  const token = localStorage.getItem(key)
  return typeof token === 'string' ? token : null
}

const baseTokenAtom = atom(hydrateStringItem(tokenStorageKey))

const tokenAtom = atom(
  (get) => get(baseTokenAtom),
  (_, set, value: TokenValue) => {
    set(baseTokenAtom, value)
    if (value) {
      localStorage.setItem(tokenStorageKey, value)
    } else {
      localStorage.removeItem(tokenStorageKey)
    }
  },
)

export function getToken() {
  return store.get(tokenAtom)
}

export function setToken(token?: TokenValue) {
  store.set(tokenAtom, token)
}

export function useToken() {
  return useAtomValue(tokenAtom)
}

export function useSetToken() {
  return useSetAtom(tokenAtom)
}

const payloadSchema = z.object({
  'uw-iat': z.number(),
  aud: z.string(),
  exp: z.number(),
  iat: z.number(),
  iss: z.string(),
  sub: z.string(),
})

// const payloadSubSchema = z.object({
//   accountCode: z.string().optional(),
//   authenticatedUserId: z.string().optional(),
//   authorities: z.array(z.any()).optional(),
//   enabledMfa: z.boolean().optional(),
//   firstName: z.string(),
//   lastName: z.string(),
//   loginMechanism: z.string().optional(),
//   loginScopes: z.array(z.string()).optional(),
//   loginType: z.string().optional(),
//   ssoUserId: z.string().optional(),
//   targetAccountCode: z.string().optional(),
//   targetAccountSlug: z.string().optional(),
//   targetSsoUserId: z.string().optional(),
//   username: z.string().optional(),
//   userType: z.string(),
// })

export const ACCOUNT_OWNER_SYMBOL = Symbol('ACCOUNT_OWNER')

export const restrictionsSchema = z
  .array(
    z.object({
      roleName: z.nativeEnum(GrantedMemberRole),
      roleScope: z.array(z.any()),
    }),
  )
  .optional()
  .transform((value) => {
    if (value === undefined) {
      return ACCOUNT_OWNER_SYMBOL
    }
    return value
  })

const userPayloadSubSchema = z.object({
  firstName: z.string(),
  lastName: z.string().optional(),
  restrictions: restrictionsSchema,
  ssoUserId: z.string(),
  targetAccounts: z.any(),
  targetAccountCode: z.string(),
  targetAccountSlug: z.string(),
  targetSsoUserId: z.string(),
  username: z.string(),
})

export type Restrictions = z.infer<typeof restrictionsSchema>

export type UserPayload = {
  email: string
  firstName: string
  lastName: string | undefined
  organizationCode: string
  organizationId: string
  organizationSlug: string
  restrictions: Restrictions
  ssoUserId: string
  targetAccounts: unknown
}

export function extractTokenPayload(jwt: string): unknown {
  try {
    // Split the token into its three parts
    const parts = jwt.split('.')

    // Decode the Base64Url encoded payload
    const payloadBase64 = parts[1]
    const decodedJson = decodeBase64Url(payloadBase64)

    return JSON.parse(decodedJson)
  } catch (error) {
    const jwtParseError = new Error('Error parsing JWT')
    jwtParseError.cause = error
    throw jwtParseError
  }
}

function decodeBase64Url(str: any) {
  // Convert Base64Url to Base64
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/')

  // Pad with '=' if needed
  const paddedBase64 = base64.padEnd(
    base64.length + ((4 - (base64.length % 4)) % 4),
    '=',
  )

  // Decode Base64
  const binString = atob(paddedBase64)

  // Convert binary string to UTF-8
  return decodeURIComponent(
    Array.from(
      binString,
      (char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2),
    ).join(''),
  )
}

const tokenPayloadAtom = atom((get) => {
  const token = get(tokenAtom)

  if (!token) {
    return null
  }

  const unknownPayload = extractTokenPayload(token)
  const payload = payloadSchema.parse(unknownPayload)

  /* The actual `sub` field has many more fields */
  const subJSON = JSON.parse(payload.sub)
  const sub = userPayloadSubSchema.parse(subJSON)

  const user: UserPayload = {
    email: sub.username,
    firstName: sub.firstName,
    lastName: sub.lastName,
    organizationCode: sub.targetAccountCode,
    organizationId: sub.targetSsoUserId,
    organizationSlug: sub.targetAccountSlug,
    restrictions: sub.restrictions,
    ssoUserId: sub.ssoUserId,
    targetAccounts: sub.targetAccounts,
  }

  const data = { ...payload, user }

  return data
})

const ssoUserAtom = atomWithQuery(() => userQueries.ssoInfo())

const userAtom = atomWithQuery(() => userQueries.info())

const fullUserAtom = atom((get) => {
  const user = get(userAtom).data?.payload
  const tokenPayload = get(tokenPayloadAtom)?.user
  if (!user || !tokenPayload) return null
  return {
    ...user,
    ...tokenPayload,
    email: user.userEmail,
    firstName: user.userFirstName,
    lastName: user.userLastName,
    profilePictureImageUrl: user.profilePictureImageUrl,
  }
})

export function getSsoUser() {
  return store.get(ssoUserAtom)
}

/**
 * We might want to go one of a few different routes w/ the user:
 * - use client-side hydrated -> localstorage::user
 * - (current) use client-side hydrated -> localstorage::token |> jwt::parse |> zod::parse
 * - use server request to get user and validate the token (no persisted state)
 * ---
 * two different places can store the data:
 * - React Query -> top-level useUser with adequte stale time and React context
 * - (current) Jotai atom
 */
export function useUser() {
  return useAtomValue(fullUserAtom)
}

export function useRequiredUser() {
  const user = useUser()
  invariant(user, 'Current user is not found')
  return user
}

export function getTokenPayload() {
  return store.get(tokenPayloadAtom)
}

export function getUserPayload() {
  return store.get(tokenPayloadAtom)?.user
}

const baseRefreshAtom = atom(hydrateStringItem(refreshStorageKey))

const refreshAtom = atom(
  (get) => get(baseRefreshAtom),
  (_, set, value: TokenValue) => {
    set(baseRefreshAtom, value)
    if (value) {
      localStorage.setItem(refreshStorageKey, value)
    } else {
      localStorage.removeItem(refreshStorageKey)
    }
  },
)

export function getRefresh() {
  return store.get(refreshAtom)
}

export function setRefresh(token?: TokenValue) {
  store.set(refreshAtom, token)
}

export function clearAll() {
  setToken()
  setRefresh()
}

export const auth = {
  getToken,
  setToken,
  useToken,
  useSetToken,
  useUser,
  useRequiredUser,
  getTokenPayload,
  getUserPayload,
  getRefresh,
  setRefresh,
  clearAll,
  getSsoUser,
  fullUserAtom,
}
