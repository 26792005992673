type Time = {
  getMs: () => number
  getSeconds: () => number
  getMinutes: () => number
}

function createTime(ms: number): Time {
  return {
    getMs: () => ms,
    getSeconds: () => ms / 1000,
    getMinutes: () => ms / (1000 * 60),
  }
}

export const time = {
  fromMs: (ms: number): Time => {
    return createTime(ms)
  },
  fromSeconds: (seconds: number): Time => {
    return createTime(seconds * 1000)
  },
  fromMinutes: (minutes: number): Time => {
    return createTime(minutes * 1000 * 60)
  },
}
