import { cx } from 'class-variance-authority'
import { extendTailwindMerge } from 'tailwind-merge'

/**
 * `clsx` wrapper that also merges TailwindCSS classes w/o conflicts
 */
const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-title',
        'text-heading-1',
        'text-heading-2',
        'text-heading-3',
        'text-body-1',
        'text-body-2',
        'text-body-3',
        'text-button',
        'text-caption',
        'text-assistive',
      ],
    },
  },
})
export const cn: typeof cx = (...classes) => customTwMerge(cx(classes))

/**
 * Flatten complex types
 */
export type Simplify<TType> = TType extends any[] | Date
  ? TType
  : { [K in keyof TType]: TType[K] }

export async function getFormData(request: Request) {
  return Object.fromEntries(await request.formData())
}

/**
 * Utility to make strict unions more friendly to use
 */
export type TheUnion<T> = StrictUnionHelper<T, T>
type UnionKeys<T> = T extends T ? keyof T : never
type UnionDiff<T, TAll> = Exclude<UnionKeys<TAll>, keyof T>
type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<UnionDiff<T, TAll>, undefined>>
  : never

/**
 * Identity function to trigger TailwindCSS Intellisense
 */
export const tw = <A extends string>(a: A) => a

export const camelToKebab = (camelCase: string) =>
  camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

/**
 * Ensures all cases are handled in a switch statement.
 * @param a - The value that should never occur if all cases are handled.
 */
export function assertNever(a: never): never {
  return a
}
