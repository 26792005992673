import {
  LawsuitRiskStatus,
  ProjectSortField,
  ScanRuleLevel,
  ScanRuleSeverity,
  SortOrder,
  TestFramework,
  AccessibilityScoreFilter,
  Integration,
  ScanScoreOutcome,
  NumberScansFilter,
} from '@userway/cicd-api'
import { SelectOption, Option } from '~/lib/utils/search'

export const name = {
  a11yScore: 'a11yScore',
  lawsuitRisk: 'lawsuitRisk',
  page: 'pageIndex',
  qualityGateStatus: 'qualityGateStatus',
  searchText: 'text',
  severity: 'severity',
  sortBy: 'field',
  sortOrder: 'order',
  testFrameworks: 'testFrameworks',
  wcag: 'wcag',
  integration: 'integration',
  managedScans: 'managedScans',
  selfHostedScans: 'selfHostedScans',
} as const

export const checkboxFilters = [
  name.a11yScore,
  name.lawsuitRisk,
  name.qualityGateStatus,
  name.severity,
  name.testFrameworks,
  name.wcag,
  name.integration,
  name.managedScans,
  name.selfHostedScans,
]

const AccessibilityScoreFilters = Object.values(AccessibilityScoreFilter)

const lawsuitRiskValues = Object.values(LawsuitRiskStatus)

const qualityGateValues = Object.values(ScanScoreOutcome)

const severityValues = Object.values(ScanRuleSeverity)

const testFrameworkValues = Object.values(TestFramework)

const wcagValues = Object.values(ScanRuleLevel)

const integrationValues = Object.values(Integration)

const managedScansValues = Object.values(NumberScansFilter)

const selfHostedScansValues = Object.values(NumberScansFilter)

const sortByValues = Object.values(ProjectSortField)

const sortOrderValues = Object.values(SortOrder)

type SortingValue = `${ProjectSortField}-${SortOrder}`

export const options = {
  sorting: {
    'name-asc': {
      itemText: 'Sort by name (A-Z)',
      label: 'Name (A-Z)',
      value: 'name-asc',
    },
    'name-desc': {
      itemText: 'Sort by name (Z-A)',
      label: 'Name (Z-A)',
      value: 'name-desc',
    },
    'severity-asc': {
      itemText: 'Sort by lowest severity',
      label: 'Lowest severity',
      value: 'severity-asc',
    },
    'severity-desc': {
      itemText: 'Sort by highest severity',
      label: 'Highest severity',
      value: 'severity-desc',
    },
    'analyzedAt-asc': {
      itemText: 'Sort by oldest scan',
      label: 'Oldest scan',
      value: 'analyzedAt-asc',
    },
    'analyzedAt-desc': {
      itemText: 'Sort by newest scan',
      label: 'Newest scan',
      value: 'analyzedAt-desc',
    },
  } as const satisfies Record<SortingValue, SelectOption<SortingValue>>,

  [name.a11yScore]: {
    MORE_THAN_SEVENTY_FIVE: {
      label: '>75%',
      value: AccessibilityScoreFilter.MORE_THAN_SEVENTY_FIVE,
    },
    BETWEEN_FIFTY_SEVENTY_FIVE: {
      label: '50%-75%',
      value: AccessibilityScoreFilter.BETWEEN_FIFTY_SEVENTY_FIVE,
    },
    LESS_THAN_FIFTY: {
      label: '<50%',
      value: AccessibilityScoreFilter.LESS_THAN_FIFTY,
    },
  } as const satisfies Record<
    AccessibilityScoreFilter,
    Option<AccessibilityScoreFilter>
  >,

  [name.lawsuitRisk]: {
    HIGH: { label: 'High', value: LawsuitRiskStatus.HIGH },
    LOW: { label: 'Low', value: LawsuitRiskStatus.LOW },
  } as const satisfies Record<LawsuitRiskStatus, Option<LawsuitRiskStatus>>,
  [name.qualityGateStatus]: {
    PASSED: { label: 'Passed', value: ScanScoreOutcome.PASSED },
    FAILED: { label: 'Failed', value: ScanScoreOutcome.FAILED },
  } as const satisfies Record<ScanScoreOutcome, Option<ScanScoreOutcome>>,

  [name.severity]: {
    CRITICAL: { label: 'Critical', value: ScanRuleSeverity.CRITICAL },
    SERIOUS: { label: 'Serious', value: ScanRuleSeverity.SERIOUS },
    MODERATE: { label: 'Moderate', value: ScanRuleSeverity.MODERATE },
    MINOR: { label: 'Minor', value: ScanRuleSeverity.MINOR },
  } as const satisfies Record<ScanRuleSeverity, Option<ScanRuleSeverity>>,

  [name.testFrameworks]: {
    CYPRESS: { label: 'Cypress', value: TestFramework.CYPRESS },
    PLAYWRIGHT: { label: 'Playwright', value: TestFramework.PLAYWRIGHT },
    PUPPETEER: { label: 'Puppeteer', value: TestFramework.PUPPETEER },
    SELENIUM_JAVA: {
      label: 'Selenium Java',
      value: TestFramework.SELENIUM_JAVA,
    },
    SELENIUM_CSHARP: {
      label: 'Selenium C#',
      value: TestFramework.SELENIUM_CSHARP,
    },
    SELENIUM_PYTHON: {
      label: 'Selenium Python',
      value: TestFramework.SELENIUM_PYTHON,
    },
    USERWAY: { label: 'UserWay', value: TestFramework.USERWAY },
    WEBDRIVERIO: { label: 'WebdriverIO', value: TestFramework.WEBDRIVERIO },
  } as const satisfies Record<TestFramework, Option<TestFramework>>,

  [name.wcag]: {
    A: { label: 'A', value: ScanRuleLevel.A },
    AA: { label: 'AA', value: ScanRuleLevel.AA },
    AAA: { label: 'AAA', value: ScanRuleLevel.AAA },
    BESTPRACTICE: {
      label: 'Best Practices',
      value: ScanRuleLevel.BESTPRACTICE,
    },
    EXPERIMENTAL: { label: 'Experimental', value: ScanRuleLevel.EXPERIMENTAL },
  } as const satisfies Record<ScanRuleLevel, Option<ScanRuleLevel>>,

  [name.integration]: {
    AZURE: { label: 'Azure', value: Integration.AZURE },
    BITBUCKET: { label: 'Bitbucket', value: Integration.BITBUCKET },
    GITHUB: { label: 'GitHub', value: Integration.GITHUB },
    GITLAB: { label: 'GitLab', value: Integration.GITLAB },
    MANUAL: { label: 'Manual', value: Integration.MANUAL },
  } as const satisfies Record<Integration, Option<Integration>>,

  [name.managedScans]: {
    LESS_THAN_FIVE: { label: '<5', value: NumberScansFilter.LESS_THAN_FIVE },
    BETWEEN_FIVE_TWENTY: {
      label: '5-20',
      value: NumberScansFilter.BETWEEN_FIVE_TWENTY,
    },
    BETWEEN_TWENTY_FIFTY: {
      label: '20-50',
      value: NumberScansFilter.BETWEEN_TWENTY_FIFTY,
    },
    BETWEEN_FIFTY_HUNDRED: {
      label: '50-100',
      value: NumberScansFilter.BETWEEN_FIFTY_HUNDRED,
    },
    MORE_THAN_HUNDRED: {
      label: '100+',
      value: NumberScansFilter.MORE_THAN_HUNDRED,
    },
  } as const satisfies Record<NumberScansFilter, Option<NumberScansFilter>>,

  [name.selfHostedScans]: {
    LESS_THAN_FIVE: { label: '<5', value: NumberScansFilter.LESS_THAN_FIVE },
    BETWEEN_FIVE_TWENTY: {
      label: '5-20',
      value: NumberScansFilter.BETWEEN_FIVE_TWENTY,
    },
    BETWEEN_TWENTY_FIFTY: {
      label: '20-50',
      value: NumberScansFilter.BETWEEN_TWENTY_FIFTY,
    },
    BETWEEN_FIFTY_HUNDRED: {
      label: '50-100',
      value: NumberScansFilter.BETWEEN_FIFTY_HUNDRED,
    },
    MORE_THAN_HUNDRED: {
      label: '100+',
      value: NumberScansFilter.MORE_THAN_HUNDRED,
    },
  } as const satisfies Record<NumberScansFilter, Option<NumberScansFilter>>,
}

export const optionsList = {
  [name.a11yScore]: Object.values(options['a11yScore']),
  [name.lawsuitRisk]: Object.values(options['lawsuitRisk']),
  [name.qualityGateStatus]: Object.values(options['qualityGateStatus']),
  [name.severity]: Object.values(options['severity']),
  [name.testFrameworks]: Object.values(options['testFrameworks']),
  [name.wcag]: Object.values(options['wcag']),
  [name.integration]: Object.values(options['integration']),
  [name.managedScans]: Object.values(options['managedScans']),
  [name.selfHostedScans]: Object.values(options['selfHostedScans']),
  sorting: Object.values(options.sorting),
}

export function parseSearchParams(search: URLSearchParams): Filters {
  const text = search.get(name.searchText) || ''

  const pageIndex = Number(search.get(name.page) ?? 0)

  const a11yScore = search
    .getAll(name.a11yScore)
    .filter((value): value is AccessibilityScoreFilter => {
      return AccessibilityScoreFilters.includes(value)
    })

  const lawsuitRisk = search
    .getAll(name.lawsuitRisk)
    .filter((value): value is LawsuitRiskStatus => {
      return lawsuitRiskValues.includes(value)
    })

  const qualityGateStatus = search
    .getAll(name.qualityGateStatus)
    .filter((value): value is ScanScoreOutcome => {
      return qualityGateValues.includes(value)
    })

  const severity = search
    .getAll(name.severity)
    .filter((value): value is ScanRuleSeverity => {
      return severityValues.includes(value)
    })

  const testFrameworks = search
    .getAll(name.testFrameworks)
    .filter((value): value is TestFramework => {
      return testFrameworkValues.includes(value)
    })

  const wcag = search
    .getAll(name.wcag)
    .filter((value): value is ScanRuleLevel => {
      return wcagValues.includes(value)
    })

  const integration = search
    .getAll(name.integration)
    .filter((value): value is Integration => {
      return integrationValues.includes(value)
    })

  const managedScans = search
    .getAll(name.managedScans)
    .filter((value): value is NumberScansFilter => {
      return managedScansValues.includes(value)
    })

  const selfHostedScans = search
    .getAll(name.selfHostedScans)
    .filter((value): value is NumberScansFilter => {
      return selfHostedScansValues.includes(value)
    })

  const sortBy = search.get(name.sortBy) || ''
  const field = sortByValues.includes(sortBy) ? sortBy : ProjectSortField.NAME

  const sortOrder = search.get(name.sortOrder) || ''
  const order = sortOrderValues.includes(sortOrder) ? sortOrder : SortOrder.ASC

  return {
    a11yScore,
    field,
    lawsuitRisk,
    order,
    pageIndex: isNaN(pageIndex) ? 0 : pageIndex,
    pageSize: 20,
    qualityGateStatus,
    severity,
    testFrameworks,
    text,
    wcag,
    integration,
    managedScans,
    selfHostedScans,
  }
}

export type Filters = {
  a11yScore: string[]
  field: string
  lawsuitRisk: string[]
  order: string
  pageIndex: number
  pageSize: number
  qualityGateStatus: string[]
  severity: string[]
  testFrameworks: string[]
  text: string
  wcag: string[]
  integration: string[]
  managedScans: string[]
  selfHostedScans: string[]
}
