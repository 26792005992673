import { VariantProps, cva } from 'class-variance-authority'
import { NavLink } from 'react-router-dom'
import { cn } from '~/lib/utils'

const textLinkVariant = cva(
  'rounded-sm underline underline-offset-2 transition-colors focus-visible:outline-offset-4',
  {
    variants: {
      color: {
        accent:
          'text-accent-text-1 hover:brightness-90 dark:hover:brightness-110',
        text: 'text-app-text hover:text-app-text-hi',
      },
    },
    defaultVariants: {
      color: 'accent',
    },
  },
)

type TextLinkVariants = VariantProps<typeof textLinkVariant>

type LinkBaseProps = Omit<
  React.JSX.IntrinsicElements['a'],
  'href' | 'style' | 'ref'
>

type TextLinkProps = TextLinkVariants & {
  to: string
} & LinkBaseProps

export function TextLink(props: TextLinkProps) {
  const { className, to, children, color, ...linkProps } = props

  const isExternal = /^https?:\/\//.test(to)

  const classes = cn(textLinkVariant({ color, className }))

  if (isExternal) {
    return (
      <a className={classes} href={to} {...linkProps}>
        {children}
      </a>
    )
  }

  return (
    <NavLink to={to} className={classes} {...linkProps}>
      {children}
    </NavLink>
  )
}
