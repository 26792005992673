export function TouchTarget(props: { children: React.ReactNode }) {
  return (
    <>
      {props.children}
      <span
        aria-hidden="true"
        className={`
          absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] 
          -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden
        `}
      />
    </>
  )
}
