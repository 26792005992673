import { QualityGateRuleStatus, ScoreCellMetric } from '@userway/cicd-api'
import { z } from 'zod'

export * from './router'
export * from './server'

export * as q from '~/lib/query'

/**
 * Helper function for creating a standardized API response schema using zod
 * @example
 * const getUserResponseSchema = responseSchema(z.object({ name: z.string() }))
 * const UserResponse = z.infer<typeof getUserResponseSchema>
 * // { payload: { name: string }, payloadType: string }
 */
export function responseSchema<T extends z.ZodType>(payload: T) {
  return z.object({
    payload: payload,
    payloadType: z.string(),
  })
}

export type ResponseBody<Payload = any> = {
  payload: Payload
  payloadType: string
}

/**
 * Helper function for creating a paginated API response schema using zod
 * @example
 * const getUsersResponseSchema = paginatedResponseSchema(z.object({ name: z.string() }))
 * const UsersResponse = z.infer<typeof getUsersResponseSchema>
 * // { payload: { thisPageItems: Array<{ name: string }>, ... }, ... }
 */
export function paginatedItems<T extends z.ZodType>(item: T) {
  return z.object({
    lastPageIndex: z.number(),
    moreAvailable: z.boolean(),
    pageItemType: z.string(),
    thisPageIndex: z.number(),
    thisPageItems: z.array(item),
    totalNumber: z.number(),
  })
}

export function paginatedResponseSchema<T extends z.ZodType>(item: T) {
  return responseSchema(paginatedItems(item))
}

export const dateSchema = z.object({
  dateTimestamp: z.number(),
  dateReadable: z.string(),
})

export const messageResponseSchema = z.object({
  msg: z.string(),
  aux: z.string().nullable(),
})

export const scoreCellSchema = z.object({
  metric: z.nativeEnum(ScoreCellMetric),
  value: z.union([z.string(), z.number()]),
  status: z.nativeEnum(QualityGateRuleStatus),
})

export type PaginatedResponseBody<Item = any> = ResponseBody<{
  lastPageIndex: number
  moreAvailable: boolean
  pageItemType: string
  thisPageIndex: number
  thisPageItems: Array<Item>
  totalNumber: number
}>

export type PaginationParams = {
  pageIndex?: number | undefined
  pageSize?: number | undefined
}
