import { atom, useAtom } from 'jotai'
import { z } from 'zod'
import {
  fontStorageKey,
  themeStorageKey,
  variantStorageKey,
} from '~/feat/storage/const'
import { useMediaQuery } from '~/lib/use-media-query'

export enum Theme {
  system = 'system',
  dark = 'dark',
  light = 'light',
}
export enum ThemeVariant {
  contrast = 'contrast',
  gray = 'gray',
  slate = 'slate',
  mauve = 'mauve',
  sand = 'sand',
}
export enum FontFamily {
  metropolis = 'metropolis',
  inter = 'inter',
  dual = 'dual',
}

const themeSchema = z.nativeEnum(Theme)
const variantSchema = z.nativeEnum(ThemeVariant)
const fontSchema = z.nativeEnum(FontFamily)

function hydrateTheme() {
  const fromStorage = localStorage.getItem(themeStorageKey)
  const result = themeSchema.safeParse(fromStorage)
  return result.success ? result.data : Theme.dark
}
function hydrateVariant() {
  const fromStorage = localStorage.getItem(variantStorageKey)
  const result = variantSchema.safeParse(fromStorage)
  return result.success ? result.data : ThemeVariant.gray
}
function hydrateFont() {
  const fromStorage = localStorage.getItem(fontStorageKey)
  const result = fontSchema.safeParse(fromStorage)
  return result.success ? result.data : FontFamily.dual
}

const baseThemeAtom = atom(hydrateTheme())
const baseVariantAtom = atom(hydrateVariant())
const baseFontAtom = atom(hydrateFont())

const themeAtom = atom(
  (get) => get(baseThemeAtom),
  (_, set, value: Theme) => {
    set(baseThemeAtom, value)
    localStorage.setItem(themeStorageKey, value)
  },
)
const variantAtom = atom(
  (get) => get(baseVariantAtom),
  (_, set, value: ThemeVariant) => {
    set(baseVariantAtom, value)
    localStorage.setItem(variantStorageKey, value)
  },
)
const fontAtom = atom(
  (get) => get(baseFontAtom),
  (_, set, value: FontFamily) => {
    set(baseFontAtom, value)
    localStorage.setItem(fontStorageKey, value)
  },
)

export function useTheme() {
  return useAtom(themeAtom)
}

/* Handles system preference and always returns dark or light */
export function useThemeValue() {
  const [theme] = useTheme()
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)')

  if (theme === Theme.system) {
    return isSystemDark ? Theme.dark : Theme.light
  }

  return theme
}

export function useThemeVariant() {
  return useAtom(variantAtom)
}

export function useFontFamily() {
  return useAtom(fontAtom)
}
