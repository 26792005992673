import '~/styles/app.css'
import '~/styles/font-jetbrains-mono.css'
import '~/styles/font-metropolis.css'
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
  matchQuery,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider as StoreProvider } from 'jotai'
import { useHydrateAtoms } from 'jotai/react/utils'
import { queryClientAtom } from 'jotai-tanstack-query'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'
import { RootErrorBoundary } from '~/components/error-boundary/root-error'
import { FontSwitcher, ThemeSwitcher } from '~/feat/theme/components'
import { shouldThrowLayoutError } from '~/lib/error-helpers'
import { store } from '~/lib/store'
import { createRouter } from '~/router'

/* reload page on chunk loading error, see https://vitejs.dev/guide/build#load-error-handling */
window.addEventListener('vite:preloadError', () => {
  // TODO we might need to add a local (or session) level flag to break on consequent reloads to prevent deathloop
  window.location.reload()
})

/* temporary fallback */
const fallback = (
  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 animate-pulse">
    Loading...
  </div>
)

async function main() {
  const HydrateAtoms = ({ children }: { children: React.ReactNode }) => {
    useHydrateAtoms([[queryClientAtom, queryClient]])
    return children
  }
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      // eslint-disable-next-line max-params
      onSuccess: (_data, _variables, _context, mutation) => {
        const invalidates = mutation.meta?.invalidates

        if (!invalidates) {
          queryClient.invalidateQueries()
          return
        }

        queryClient.invalidateQueries({
          predicate: (query) => {
            // invalidate all matching tags at once
            return invalidates.some((queryKey) =>
              matchQuery({ queryKey }, query),
            )
          },
        })
      },
    }),
    defaultOptions: {
      queries: {
        throwOnError: (error) => shouldThrowLayoutError(error),
      },
    },
  })
  const router = createRouter(queryClient)

  const rootDiv = document.getElementById('root')
  if (rootDiv) {
    createRoot(rootDiv).render(
      <StrictMode>
        <ErrorBoundary fallback={<RootErrorBoundary />}>
          <StoreProvider store={store}>
            <QueryClientProvider client={queryClient}>
              <HydrateAtoms>
                <RouterProvider router={router} fallbackElement={fallback} />
                <ReactQueryDevtools client={queryClient} />
                <Toaster />
                <ThemeSwitcher />
                <FontSwitcher />
              </HydrateAtoms>
            </QueryClientProvider>
          </StoreProvider>
        </ErrorBoundary>
      </StrictMode>,
    )
  } else {
    throw new Error(`Can't find root element to render the app!`)
  }
}

const ENABLE_MSW = false

async function enableMSW() {
  if (process.env.NODE_ENV !== 'development' || !ENABLE_MSW) {
    return
  }
  return import('~/testing/browser').then(({ worker }) => worker.start())
}

enableMSW().then(main).catch(console.error)

/* TODO: add this to the index.html */
// function rehydrateTheme() {
//   try {
//     let docEl = document.documentElement
//     let docClasses = docEl.classList
//     docClasses.remove('light', 'dark')
//     let theme = localStorage.getItem('userway-ca11y-theme')
//     if ('system' === theme || !theme) {
//       let t = '(prefers-color-scheme: dark)',
//         m = window.matchMedia(t)
//       if (m.media !== t || m.matches) {
//         docEl.style.colorScheme = 'dark'
//         docClasses.add('dark-theme')
//       } else {
//         docEl.style.colorScheme = 'light'
//         docClasses.add('light-theme')
//       }
//     } else if (theme) {
//       let x = { light: 'light-theme', dark: 'dark-theme' }
//       docClasses.add(x[theme as keyof typeof x] || '')
//     }
//     if (theme === 'light' || theme === 'dark') docEl.style.colorScheme = theme
//   } catch (e) {}
// }
