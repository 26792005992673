import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { TailspinSpinner } from '~/components/ui/spinners/tailspin'
import { TouchTarget } from '~/components/ui/touch-target/touch-target'
import { cn, tw } from '~/lib/utils'

/* TODO: disable state for variant="surface" */

const baseClasses = cn(
  /* positioning */
  'relative inline-flex select-none items-center justify-center',

  /* font */
  'text-button',

  /* transition */
  // TODO: we might want to specifiy the animations instead of using "all"
  'transition-all',

  /* disabled */
  // TODO: to use cursor-not-allowed we need to and enabled: modifier for hover: and active:
  'aria-disabled:pointer-events-none',
)

export const buttonVariants = cva(baseClasses, {
  variants: {
    shape: {
      base: 'rounded-lg',
      rounded: 'rounded-full',
    },

    size: {
      xs: 'h-6 gap-1 px-1.5 py-1 text-assistive',
      sm: 'h-8 gap-2 px-3 py-1.5 text-body-2 font-medium',
      md: 'h-10 gap-2.5 px-4 py-1.5',
      lg: 'h-12 gap-3 px-4 py-1.5',
    },

    icon: {
      true: 'aspect-square p-0',
    },

    tone: {
      primary: '',
      accent: '',

      red: '',
      green: '',
      blue: '',
      yellow: '',

      cyan: '',
      mint: '',
    },

    variant: {
      blank: '',

      solid: cn(
        'hover:brightness-90 active:brightness-75',
        'aria-disabled:opacity-50',
      ),

      surface: 'border bg-transparent aria-disabled:opacity-50',

      ghost: 'bg-transparent aria-disabled:opacity-50',

      bordered: 'border aria-disabled:opacity-50',

      gradient: 'bg-gradient-to-b aria-disabled:opacity-50',

      text: 'bg-transparent aria-disabled:opacity-50',

      /* Filter panel specific variant */
      'clear-filter': tw(
        'bg-neutral-300 text-button font-normal text-neutral-700 aria-disabled:opacity-50',
      ),
    },

    pending: {
      true: tw('opacity-80 aria-disabled:opacity-80'),
    },
  },

  compoundVariants: [
    /* Shapes & Sizes */
    { shape: 'base', size: 'xs', className: 'rounded-[4px]' },
    { shape: 'base', size: 'sm', className: 'rounded-lg' },
    { shape: 'base', size: 'lg', className: 'rounded-xl' },
    { shape: 'rounded', size: 'sm', className: 'rounded-3xl' },
    { icon: false, shape: 'rounded', size: 'md', className: 'px-5' },
    { icon: false, shape: 'rounded', size: 'lg', className: 'px-5' },

    /* Variants & Tones */

    /* Solid variant */
    {
      variant: 'solid',
      tone: 'primary',
      className: cn(
        'bg-app-contrast-hi text-app-contrast-lo hover:bg-app-contrast-hi/90 active:bg-app-contrast-hi/80',
        'hover:brightness-100 active:brightness-100',
        'aria-disabled:text-app-contrast-lo/75',
      ),
    },
    {
      variant: 'solid',
      tone: 'red',
      className: 'bg-red-solid-1 text-white',
    },
    {
      variant: 'solid',
      tone: 'yellow',
      className: 'bg-yellow-solid-1 text-app-darkest',
    },
    {
      variant: 'solid',
      tone: 'green',
      className: 'bg-green-solid-1 text-white',
    },
    {
      variant: 'solid',
      tone: 'blue',
      className: tw('bg-blue-solid-1 text-app-lightest'),
    },
    {
      variant: 'solid',
      tone: 'cyan',
      className: tw('bg-cyan-100 text-cyan-500'),
    },
    {
      variant: 'solid',
      tone: 'mint',
      className: tw('bg-mint-500 text-app-darkest'),
    },

    /* Surface variant */
    {
      variant: 'surface',
      tone: 'primary',
      className: cn(
        'border-app-overlay/15 bg-app-surface text-app-text',
        'hover:bg-app-overlay/4 active:bg-app-overlay/8',
      ),
    },
    {
      variant: 'surface',
      tone: 'red',
      className: tw(
        'border-red-border-2 bg-red-bg-1 text-red-text-1 hover:bg-red-bg-2 active:bg-red-bg-3',
      ),
    },
    {
      variant: 'surface',
      tone: 'green',
      className: tw(
        'border-green-border-2 bg-green-bg-1 text-green-text-1 hover:bg-green-bg-2 active:bg-green-bg-3',
      ),
    },
    {
      variant: 'surface',
      tone: 'blue',
      className: tw(
        'border-blue-border-2 bg-blue-bg-1 text-blue-text-1 hover:bg-blue-bg-2 active:bg-blue-bg-3',
      ),
    },
    {
      variant: 'surface',
      tone: 'yellow',
      className: tw(
        'border-yellow-border-2 bg-yellow-bg-1 text-yellow-text-1 hover:bg-yellow-bg-2 active:bg-yellow-bg-3',
      ),
    },

    {
      variant: 'surface',
      tone: 'cyan',
      className: cn(
        'border-cyan-border-2 bg-cyan-bg-1 text-cyan-text-1 hover:bg-cyan-bg-2 active:bg-cyan-bg-3',
        'system:hover:bg-rc-mint-8/30 system:active:bg-rc-mint-8/20 system:dark:hover:bg-rc-mint-8/20 system:dark:active:bg-rc-mint-8/30 system:border-rc-mint-8',
      ),
    },
    {
      variant: 'surface',
      tone: 'mint',
      className: tw(
        'border-mint-border-2 bg-mint-bg-1 text-mint-text-1 hover:bg-mint-bg-2 active:bg-mint-bg-3',
      ),
    },

    /* Ghost variant */
    {
      variant: 'ghost',
      tone: 'primary',
      className: tw(
        'text-app-text hover:bg-app-overlay/4 active:bg-app-overlay/8',
      ),
    },
    {
      variant: 'ghost',
      tone: 'red',
      className: tw('text-red-text-1 hover:bg-red-bg-2 active:bg-red-bg-3'),
    },
    {
      variant: 'ghost',
      tone: 'green',
      className: tw(
        'text-green-text-1 hover:bg-green-bg-2 active:bg-green-bg-3',
      ),
    },
    {
      variant: 'ghost',
      tone: 'blue',
      className: tw('text-blue-text-1 hover:bg-blue-bg-2 active:bg-blue-bg-3'),
    },
    {
      variant: 'ghost',
      tone: 'yellow',
      className: tw(
        'text-yellow-text-1 hover:bg-yellow-bg-2 active:bg-yellow-bg-3',
      ),
    },
    {
      variant: 'ghost',
      tone: 'cyan',
      className: tw('text-cyan-text-1 hover:bg-cyan-bg-2 active:bg-cyan-bg-3'),
    },
    {
      variant: 'ghost',
      tone: 'mint',
      className: tw('text-mint-text-1 hover:bg-mint-bg-2 active:bg-mint-bg-3'),
    },

    /* Gradient */
    {
      variant: 'gradient',
      tone: 'primary',
      class: cn(
        'bg-gradient-to-b text-app-contrast-lo',

        'from-app-contrast-hi/80 to-app-contrast-hi/100',
        'hover:from-app-contrast-hi/75 hover:to-app-contrast-hi/95',
        'active:from-app-contrast-hi/70 active:to-app-contrast-hi/90',

        'dark:from-app-contrast-hi/100 dark:via-app-contrast-hi/85 dark:to-app-contrast-hi/65',
        'dark:hover:from-app-contrast-hi/95 dark:hover:via-app-contrast-hi/80 dark:hover:to-app-contrast-hi/60',
        'dark:active:from-app-contrast-hi/90 dark:active:via-app-contrast-hi/75 dark:active:to-app-contrast-hi/55',
      ),
    },

    /* Bordered */
    {
      variant: 'bordered',
      tone: 'primary',
      class: tw(
        'border-app-overlay/15 text-app-text hover:bg-app-overlay/4 active:bg-app-overlay/8',
      ),
    },
    {
      variant: 'bordered',
      tone: 'red',
      class: tw(
        'border-red-border-1 text-red-text-1 hover:border-red-border-2 hover:bg-red-bg-1 active:bg-red-bg-2',
      ),
    },
    {
      variant: 'bordered',
      tone: 'green',
      class: tw(
        'border-green-border-1 text-green-text-1 hover:border-green-border-2 hover:bg-green-bg-1 active:bg-green-bg-2',
      ),
    },
    {
      variant: 'bordered',
      tone: 'blue',
      class: tw(
        'border-blue-border-1 text-blue-text-1 hover:border-blue-border-2 hover:bg-blue-bg-1 active:bg-blue-bg-2',
      ),
    },
    {
      variant: 'bordered',
      tone: 'yellow',
      class: tw(
        'border-yellow-border-1 text-yellow-text-1 hover:border-yellow-border-2 hover:bg-yellow-bg-1 active:bg-yellow-bg-2',
      ),
    },
    {
      variant: 'bordered',
      tone: 'cyan',
      class: tw(
        'border-cyan-border-1 text-cyan-text-1 hover:border-cyan-border-2 hover:bg-cyan-bg-1 active:bg-cyan-bg-2',
      ),
    },
    {
      variant: 'bordered',
      tone: 'mint',
      class: tw(
        'border-mint-border-1 text-mint-text-1 hover:border-mint-border-2 hover:bg-mint-bg-1 active:bg-mint-bg-2',
      ),
    },

    /* Text */
    {
      variant: 'text',
      tone: 'primary',
      class: tw('text-app-text-lo hover:text-app-text-hi'),
    },
    {
      variant: 'text',
      tone: 'accent',
      class: tw('text-accent-text-1 hover:text-app-text-hi'),
    },
    {
      variant: 'text',
      tone: 'blue',
      class: tw('text-blue-text-1 hover:text-app-text-hi'),
    },
    {
      variant: 'text',
      tone: 'cyan',
      class: tw('text-cyan-text-1 hover:text-app-text-hi'),
    },
  ],

  defaultVariants: {
    icon: false,
    shape: 'base',
    size: 'md',
    tone: 'primary',
    variant: 'solid',
  },
})

type ButtonElementProps = React.ComponentPropsWithoutRef<'button'>
export type ButtonVariantProps = VariantProps<typeof buttonVariants>
export type ButtonBaseProps = ButtonElementProps & ButtonVariantProps

export type ButtonProps = Omit<ButtonBaseProps, 'pending'> & {
  asChild?: boolean
  classes?: { content?: string; rightElement?: string; leftElement?: string }
  leftIcon?: React.ReactNode | undefined
  pending?: boolean | 'left' | 'right'
  rightIcon?: React.ReactNode | undefined
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      asChild = false,
      children,
      className,
      classes = {},
      icon,
      leftIcon,
      pending,
      rightIcon,
      shape,
      size,
      tone,
      variant,
      ...buttonProps
    } = props
    const Comp = asChild ? Slot : 'button'

    const buttonClasses = cn(
      buttonVariants({
        className,
        tone,
        shape,
        size,
        icon,
        variant,
        pending: Boolean(pending),
      }),
    )
    const smallIconGap = shape === 'rounded' && (size === 'lg' || size === 'md')

    const pendingDir = pending ? (pending === 'left' ? 'left' : 'right') : null

    const rightElement =
      pendingDir === 'right' ? (
        <span
          role="presentation"
          className={cn(
            smallIconGap ? '-mr-1' : '-mr-1.5',
            'flex w-6 items-center justify-center',
            classes.rightElement,
          )}
        >
          <TailspinSpinner size={18} />
        </span>
      ) : rightIcon ? (
        <span
          role="presentation"
          className={cn(
            smallIconGap ? '-mr-1' : '-mr-1.5',
            classes.rightElement,
          )}
        >
          {rightIcon}
        </span>
      ) : null

    const leftElement =
      pendingDir === 'left' ? (
        <span
          role="presentation"
          className={cn(
            smallIconGap ? '-ml-1' : '-ml-1.5',
            'flex w-6 items-center justify-center',
            classes.leftElement,
          )}
        >
          <TailspinSpinner size={18} />
        </span>
      ) : leftIcon ? (
        <span
          role="presentation"
          className={cn(
            smallIconGap ? '-ml-1' : '-ml-1.5',
            classes.leftElement,
          )}
        >
          {leftIcon}
        </span>
      ) : null

    return (
      <Comp
        className={buttonClasses}
        ref={ref}
        type="button"
        aria-disabled={pending ? true : props.disabled}
        {...buttonProps}
      >
        <TouchTarget>
          {leftElement}
          <span
            className={cn(
              'overflow-hidden text-ellipsis whitespace-nowrap',
              classes.content,
            )}
          >
            {children}
          </span>
          {rightElement}
        </TouchTarget>
      </Comp>
    )
  },
)

Button.displayName = 'Button'
